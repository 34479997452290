@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Literata:400,500,600&display=swap");

$primary: #22313c;

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: 0.2s;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: 0.2s;
}

.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  overflow: auto;
  right: 0;
  z-index: 100;

  .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);

    .inner {
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      max-height: calc(100vh - 48px);
      display: flex;
      flex-direction: column;
      min-height: 0;
      max-width: 500px;
      width: calc(100vw - 48px);
      position: relative;

      .header {
        border-bottom: 1px solid #e8e8e8;
        padding: 1.25rem 1.5rem;
        position: relative;
        z-index: 999;

        .heading {
          color: $primary;
          font-family: "Roboto", sans-serif;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 0;
        }

        .dismiss {
          position: absolute;
          right: 30px;
          top: 20px;

          .btn {
            background: #fff;
            border: none;
            border-radius: 100px;
            padding: 0;

            i {
              line-height: 0;

              svg {
                fill: $primary;
                height: 16px;
                width: 16px;
                transition: .2s;
              }
            }

            &:hover {
              i > svg {
                fill: #777;
              }
            }

            &:focus {
              outline: none;
            }
          }
        }
      }

      .body {
        overflow: auto;
        padding: 2rem 1.5rem;

        .image {
          border: 1px solid #f7f7f9;
          border-radius: 2px;
          margin-bottom: 1.5rem;
          width: 100%;
        }

        .blurb {
          color: $primary;
          font-family: "Literata", serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.9;
          margin-bottom: 0;
        }

        .link {
          display: inline-block;
          font-family: "Roboto", sans-serif;
          font-size: 15px;
          font-weight: 600;
          margin-top: 1.5rem;

          &:hover {
            border-bottom: 3px solid #2b00ff;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .wrapper .inner {
      border-radius: 0;
      max-height: none;
      max-width: none;
      height: 100vh;
      width: 100%;
    }
  }
}

.link {
  border-bottom: 3px solid #2b00ff;
  color: $primary;

  &:hover {
    color: $primary;
    cursor: pointer;
    border-bottom: 4px solid #2b00ff;
    text-decoration: none;
  }
}

.home {
  *::selection {
    background: #ffb7b7;
  }

  *::-moz-selection {
    background: #ffb7b7;
  }

  .container {
    max-width: 720px;
  }

  .label {
    color: #aaa;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    text-transform: uppercase;

    @media (max-width: 576px) {
      margin-bottom: 2rem;
    }
  }

  .intro {
    .header {
      margin-top: 100px;

      .heading {
        color: $primary;
        font-family: "Roboto", sans-serif;
        font-size: 52px;
        font-weight: 900;
        margin-bottom: 35px;
      }
    }

    .blurb {
      .text {
        color: $primary;
        font-family: "Literata", serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.9;
        margin-bottom: 0;
      }
    }
  }

  .work {
    margin-top: 75px;

    .inner {
      display: flex;
      margin-bottom: 33px;

      .header {
        margin-right: 3rem;

        .heading {
          align-items: center;
          display: flex;
          margin-bottom: 0.5rem;

          h2 {
            color: $primary;
            font-family: "Roboto", sans-serif;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 0;
          }

          i {
            cursor: pointer;
            line-height: 0;
            margin-left: 0.5rem;

            svg {
              fill: #2b00ff;
              height: 14px;
              width: 14px;
            }
          }
        }

        .subheading {
          color: $primary;
          font-family: "Literata", serif;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 0;
        }
      }

      .logo {
        margin-left: auto;
        .img {
          border: 1px solid #f7f7f9;
          border-radius: 4px;
          cursor: pointer;
          height: 65px;
          width: 65px;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .school {
    margin-top: 80px;

    .inner {
      display: flex;
      margin-bottom: 33px;

      .header {
        .heading {
          color: $primary;
          font-family: "Roboto", sans-serif;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 0.5rem;
        }

        .subheading {
          color: $primary;
          font-family: "Literata", serif;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 0;
        }
      }

      .date {
        color: $primary;
        font-size: 16px;
        font-weight: 600;
        margin-left: auto;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .project {
    margin-top: 90px;

    .group {
      cursor: pointer;
      margin-bottom: 36px;
      transition: 0.2s;

      .img {
        border: 1px solid #f7f7f9;
        border-radius: 2px;
        margin-bottom: 1rem;
        width: 100%;
      }

      .title {
        color: $primary;
        font-family: "Roboto", sans-serif;
        font-size: 17px;
        font-weight: 700;
        margin-bottom: 0.5rem;
      }

      .subtitle {
        color: $primary;
        font-family: "Literata", serif;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
      }

      &:hover {
        margin-top: -2px;
      }
    }
  }

  .stack {
    margin-top: 55px;

    .values {
      .value {
        color: $primary;
        font-size: 17px;
        font-weight: 700;
        margin-bottom: 33px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .contact {
    margin-top: 90px;
    margin-bottom: 110px;

    .blurb {
      margin-bottom: 25px;

      .call {
        color: $primary;
        font-family: "Literata", serif;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0.5rem;
      }

      .text {
        color: $primary;
        font-family: "Literata", serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.9;
        margin-bottom: 0;
      }
    }

    .values {
      .value {
        color: $primary;
        font-size: 17px;
        font-weight: 700;
        margin-bottom: 33px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .footer {
    margin: 25px 0 100px;

    .message {
      display: inline-block;
      position: relative;
      vertical-align: middle;

      span {
        color: $primary;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 500;
      }
      i {
        margin: 0 5px;

        svg {
          fill: #f95659;
          height: 16px;
          width: 16px;
        }
      }
    }
  }
}
